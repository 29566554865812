@import '../common/variables';
@import '../common/mixins';

.ttsFiltersWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: $standardBorderRadius;
  background: white;
  column-gap: 30px;

  @include transition(all);

  @media (max-width: 1070px) {
    margin-bottom: 1.3rem;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    font-size: 12px;
    justify-content: space-evenly;
  }

  // &.ttsFilterMobile {
  //   margin-bottom: 0;
  //   overflow: hidden;
  //   scale: 0;
  //   height: 0;

  //   &.ttsFilterMobileActive {
  //     height: auto;
  //     overflow: visible;
  //     scale: 1;
  //     margin-bottom: 1.3rem;
  //   }
  // }
}

.ttsListFiltersWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 290px;

  @media (max-width: 970px) {
    width: auto;
  }
}

.ttsSanitizeActive {
  color: $base-primary-600;

  &:hover {
    color: $base-primary-600;
    background-color: $base-primary-0;
  }
}

.ttsFilterCustomButton {
  flex-shrink: 0;

  &:hover {
    color: $base-primary-600;
    background-color: $base-primary-0;
  }
}

.ttsListLanguageFilter {
  width: 12rem;
  margin-top: 0.5rem;

  @media (max-width: 970px) {
    width: 100%;
  }
}

.ttsSexListFilter {
  display: flex;
  flex-direction: column;
}

.ttsSexListFilterBtns {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;

  @media (max-width: 450px) {
    justify-content: center;
  }

  & button {
    width: 48px;
    height: 48px;
    justify-content: center;

    @media (max-width: 970px) {
      width: auto;
    }

    @media (max-width: 450px) {
      width: 100%;
      justify-content: center;
    }

    &:hover {
      color: $base-primary-600;
      background-color: $base-primary-0;
    }
  }
}

.ttsFilterInputBox {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;

  .ttsFilterInputIcon {
    flex-shrink: 0;
    padding: 12px 0px 12px 12px;
    background-color: $base-50;
    margin: auto 0;
    border-top-left-radius: $standardBorderRadius;
    border-bottom-left-radius: $standardBorderRadius;
  }

  .ttsFilterInput {
    width: 100px;
    flex-grow: 1;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  & a,
  button {
    position: absolute;
    top: 10px;
    right: 3px;
    // transform: translateX(-10px);
    border-top-right-radius: $standardBorderRadius;
    border-bottom-right-radius: $standardBorderRadius;
    padding-right: 5px;
    color: rgba($dark, 0.4);
    // background-color: $base-50;
    @include transition(color);

    &:hover {
      color: rgba($dark, 0.6);
    }
  }
}

.ttsSSMLListFilter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
}

.ttsSSMLListFilterBtns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;

  & button {
    display: block;
    width: 100%;
    align-items: start;

    &:hover {
      color: $base-primary-600;
      background-color: $base-primary-0;
    }
  }
}

.active {
  color: $base-primary-600;
  background-color: $base-primary-0;
}

.ttsFilterListTitle {
  color: $base-700;
  font-size: 14px;
}

.ttsLanguageListFilter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 224px;

  @media (max-width: 970px) {
    max-width: auto;

    // justify-content: center;
    button {
      max-width: auto;
    }
  }

  button {
    &:hover {
      color: $base-primary-600;
      background-color: $base-primary-0;
    }
  }
}

.ttsLanguageListFilterBtns {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 6px;

  button {
    max-width: 195px;
  }
}

.filterButtonContent {
  max-width: 224px;
}

.ttsFilterButtons {
  display: flex;
  background: white;
  gap: 6px;
}

.ttsSSMLListFilterCkeckboxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ttsSSMLCheckBoxItem {
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  color: $base-500;

  .checked {
    color: $base-900;
  }
}
