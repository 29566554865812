@import './common/variables';
@import './common/mixins';

.loginForm {
  display: flex;
  flex-direction: column;
  max-width: 27.2rem;
  width: 95vw;
  align-items: flex-start;
}

.loginFormWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loginFields {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}

.fieldCross {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $base-500;
  transform: translateY(-50%);

  & svg {
    width: 80%;
    height: auto;
  }
}

.loginField {
  padding: 1rem 3rem 1rem 1rem;
  font-size: 1rem;
  border-radius: 5rem;
  width: 100%;

  &::placeholder {
    color: $base-500;
  }
}

.loginEnterBtn {
  width: 100%;
  background: transparent;
  border-radius: 5rem;
  color: $base-400;
  box-shadow: 0px 4px 8px rgba(0, 21, 53, 0.04), 0px 0px 2px rgba(0, 21, 53, 0.05), 0px 0px 1px rgba(0, 21, 53, 0.04);
  @include transition2(color, background);

  &.disabled {
    cursor: not-allowed;

    &:hover {
      color: $base-400;
    }
  }

  &.active {
    background: $base-primary-600;
    color: #fff;
  }
}
