@import 'variables';
$ease: ease 0.35s;

@mixin transition($properties) {
  transition: $properties $ease;
}

@mixin transition2($properties, $properties2) {
  transition: $properties $ease, $properties2 $ease;
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin inactive {
  border-color: rgba($dark, 0.2) !important;
  color: rgba($dark, 0.2) !important;

  & * {
    border-color: rgba($dark, 0.2) !important;
    color: rgba($dark, 0.2) !important;
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }

  // For Mozilla

  & {
    @if $size == 0px {
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none; /* Firefox 64 */
    } @else {
      scrollbar-width: $size; /* Firefox 64 */
    }
  }
}
