@import '../common/mixins';
@import '../common/variables';

.ttsSidebarWrapper {
  position: relative;
  background: #fff;
  padding: 2rem;
  border-radius: $standardBorderRadius;
  width: 100%;
  flex-shrink: 0;
  transition: 0.7s all ease;
  box-shadow: $standardBoxShadow;

  &.ttsSidebarWrapperSmall {
    width: 29rem;

    @media (max-width: 970px) {
      width: 100%;
    }
  }

  @media (max-width: 970px) {
    padding: 1.3rem;
  }
}

.ttsInfoText {
  font-size: 1.12rem;
  color: $base-900;
  padding-bottom: 15px;
  padding-left: 8px;
}

.ttsTextarea {
  width: 100%;
  padding: 1.3rem;
  border-radius: $standardBorderRadius;
  margin-top: 1rem;
  height: 17rem;
  max-height: 17em;
  resize: none;
  background: $base-50;

  &::placeholder {
    color: $base-600;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 90%;
    background: #0015350d;
    border-radius: $standardBorderRadius;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;

    background: #00153566;
    border-radius: $standardBorderRadius;
  }
}

.sanitizeBtn {
  width: 380px;
  max-width: 25rem;
  background: rgba($base-primary-600, 1);
  color: #fff;
  justify-content: center;
  gap: 12px;
  margin: 1.3rem 3px 0 auto;
  border-radius: 5rem;
  @include transition(background);

  @media (max-width: 971px) {
    margin: 1.3rem auto;
    max-width: 800px;
    width: 100%;
  }

  &.disabled {
    background: rgba($dark, 0.2);
    cursor: not-allowed;
  }

  &:hover {
    background: $base-primary-600;
    color: #fff;

    &.disabled {
      background: rgba($dark, 0.2);
    }
  }
}

.ttsSidebarBtns {
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  max-width: 25rem;
  margin: 1rem 5px 0;

  & a.ttsSidebarSelectBtn,
  button.ttsSidebarSelectBtn {
    flex-shrink: 0;
    font-size: 0.875rem;
    color: rgba($dark, 0.4);

    &:hover {
      color: $dark;
    }
  }

  & :global(.outClickSelectBtn) {
    flex-shrink: 0;
  }

  @media (max-width: 971px) {
    max-width: 100%;
  }

  @media (max-width: 400px) {
    max-width: unset;
    flex-wrap: wrap;
  }
}

.ttsSidebarSelectBtn {
  padding: 0;
  // & svg {
  //   color: black;
  //   fill: black;
  // }
}

.ttsSidebarSelectContent {
  top: unset;
  bottom: calc(100% + 1rem);
  right: unset;
  left: -110px;
  width: max-content;
  max-width: 20rem;
  transform: translate(0, 12%);

  @media (max-width: 768px) {
    left: -140px;
  }

  @media (max-width: 400px) {
    left: -230px;
  }

  &.examples {
    padding: 0;
  }

  &:global(.active) {
    transform: translate(0, -7%);
  }
}

.ttsSidebarAdviceContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;

  & a {
    color: $base-primary-600;
    @include transition(color);
    display: flex;
    gap: 0.25rem;

    & svg {
      color: rgba($base-primary-600, 0.4);
      @include transition(color);
    }

    &:hover {
      color: $base-primary-600;
      & svg {
        color: $base-primary-600;
      }
    }
  }
}

.examplesBtnWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.examplesBtn {
  color: $base-primary-600;
  @include transition(background);
  font-size: 1rem;
  text-align: left;
  border-radius: $standardBorderRadius;

  &:first-child {
    border-top-left-radius: $standardBorderRadius;
    border-top-right-radius: $standardBorderRadius;
  }
  &:last-child {
    border-bottom-left-radius: $standardBorderRadius;
    border-bottom-right-radius: $standardBorderRadius;
  }

  &:hover {
    background: rgba($base-primary-600, 0.1);
  }
}

.ttsSidebarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.ttsSidebarFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // grid-template-columns: 1fr 1fr;
  @media (max-width: 971px) {
    flex-direction: column;
    justify-content: space-between;
  }
}
