$font: 'Inter';

@font-face {
  font-family: $font;
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/Inter-Light.eot'); /* IE9 Compat Modes */
  src: local('Inter Light'), local('Inter-Light'),
    url('../../assets/fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/Inter-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/Inter-Light.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/Inter-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Inter-Regular.eot'); /* IE9 Compat Modes */
  src: local('Inter'), local('Inter-Regular'),
    url('../../assets/fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/Inter-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/Inter-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/Inter-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: $font;
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/Inter-Italic.eot'); /* IE9 Compat Modes */
  src: local('Inter Italic'), local('Inter-Italic'),
    url('../../assets/fonts/Inter-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/Inter-Italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/Inter-Italic.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/Inter-Italic.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Inter-Medium.eot'); /* IE9 Compat Modes */
  src: local('Inter Medium'), local('Inter-Medium'),
    url('../../assets/fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/Inter-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/Inter-Medium.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/Inter-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: $font;
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Inter-Bold.eot'); /* IE9 Compat Modes */
  src: local('Inter Bold'), local('Inter-Bold'),
    url('../../assets/fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/Inter-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../assets/fonts/Inter-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/Inter-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

html,
body {
  font-family: $font, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5rem;
}

button {
  font-family: $font, sans-serif;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

input {
  font-family: $font, sans-serif;
  border: none;
  outline: none;
  background: #fff;
  font-size: 1rem;
}

textarea {
  font-family: $font, sans-serif;
  border: none;
  outline: none;
  background: #fff;
  font-size: 1rem;
}
