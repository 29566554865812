@import 'common/mixins';
@import 'common/variables';

.header {
  @media (max-width: 1070px) {
    background: $base-bg;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0px 4px 8px rgba(0, 21, 53, 0.04), 0px 0px 2px rgba(0, 21, 53, 0.05), 0px 0px 1px rgba(0, 21, 53, 0.04);
  }
}

.headerWrapper {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 0;
}

.headerLogo {
  & a {
    color: $base-primary-600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.2rem;
  }
}

.mainBackground {
  background-image: url('../assets/images/mainBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}

.navItemsList {
  display: flex;
  gap: 1rem;
  color: $base-500;

  & .navItem {
    cursor: pointer;
    border-radius: 0.25rem;
    @include transition(background);

    & svg {
      color: $base-primary-600;
    }

    & a,
    & button {
      color: $base-500;
      display: flex;
      padding: 0.25rem;
      @include transition(color);

      &:hover {
        color: $base-900;
      }
    }

    &.navItemActive {
      background: $base-primary-600;
      & a,
      & button {
        color: #fff;
      }

      & svg {
        color: #fff;
      }
    }
  }

  @media (max-width: 1070px) {
    gap: 1rem;
  }
}

.container {
  max-width: $largestContainer;
  margin: 0 auto;
  width: 95vw;

  // @media (max-width: 1440px) {
  //   max-width: $largeContainer;
  // }

  @media (max-width: 1280px) {
    max-width: unset;
  }
}

.mainTitle {
  padding: 0.5rem;
  text-align: center;
  h1 {
    font-weight: 500;
  }

  @media (max-width: 1070px) {
    margin-top: 6rem;
    h1 {
      font-size: 24px;
    }
  }
  @media (max-width: 470px) {
    h1 {
      font-size: 20px;
    }
  }
}
