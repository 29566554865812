@import 'common/variables';

.customScrollbar {
  & :global(.ScrollbarsCustom-TrackY) {
    background-color: transparent !important;
    height: 100% !important;
    top: 0 !important;
    width: 6px !important;

    & :global(.ScrollbarsCustom-Thumb) {
      background-color: #ffffff !important;
    }
  }

  &.tts,
  .ttsModalVoicesWrapper {
    & :global(.ScrollbarsCustom-TrackY) {
      & :global(.ScrollbarsCustom-Thumb) {
        background-color: var($base-primary-600) !important;
      }
    }
  }
}
