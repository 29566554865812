@import './common/variables';
@import './common/mixins';

.notify {
  background: $base-400;
  opacity: 0;
  transition: 0.35s all ease;
  padding: 1rem;
  height: 3.25rem;
  position: fixed;
  border-radius: $standardBorderRadius;
  bottom: 0.5rem;
  left: 50%;
  z-index: 100;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 21, 53, 0.04), 0px 0px 2px rgba(0, 21, 53, 0.05), 0px 0px 1px rgba(0, 21, 53, 0.04);

  &.notifyMounted {
    opacity: 1;
  }

  @media (max-width: 576px) {
    max-width: calc(100vw - 0.5rem);
  }
}

.notifyExitBtn {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: $standardBoxShadow;
  display: flex;
}

.notifyContent {
  white-space: nowrap;
  text-align: center;
  @include truncate;
}
