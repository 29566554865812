@import '../common/variables';

.sections {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-bottom: 2rem;
}

.sectionItems {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.sectionItem {
  background: #fff;
  padding: 1.5rem;
  box-shadow: 0px 4px 8px rgba(0, 21, 53, 0.04), 0px 0px 2px rgba(0, 21, 53, 0.05), 0px 0px 1px rgba(0, 21, 53, 0.04);
  border-radius: $standardBorderRadius;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sectionItemTitle {
  font-size: 1rem;
  line-height: 1.25rem;
}

.sectionItemFileInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.divider {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background: $base-500;
}

.sectionItemBtn {
  font-size: 0.875rem;
  color: $base-primary-600;
}

.sectionFileType {
  color: $base-500;
  font-size: 0.867rem;
}
