@import 'common/variables';

.noResults {
  background-color: #ffffff;
  border-radius: $standardBorderRadius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5.42rem 2rem;

  & .title {
    color: $base-primary-600;
    margin-top: 1.3rem;
    text-align: center;
  }
}
