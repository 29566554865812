@import '../common/variables';

.dropzoneActiveWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  background-color: #0015350d;
  backdrop-filter: blur(0.8rem);

  &::before {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    content: '';
    border: 0.0675rem dashed $base-primary-600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $standardBorderRadius;
  }
}

.dropzoneTitle {
  position: fixed;
  bottom: 20px;
  left: 43%;
  z-index: 10;
}
