@import './common/variables';
@import './common/mixins';

.tooltipWrapper {
  position: relative;
  display: flex;
}

.infoTooltipIcon {
  color: rgba($base-primary-600, 0.5);
  display: flex;
  @include transition(color);

  &:hover {
    color: $base-primary-600;
  }
}

.portalTooltipWrapper {
  position: absolute;
  transition: 0.35s all ease;
  z-index: 100;
  box-shadow: $standardBoxShadow;
}

.portalTooltipContent {
  background: #fff;
  padding: 0.75rem;
  color: #000;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  max-width: 450px;

  &.triangledContent {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      background: #fff;
      width: 1rem;
      height: 1rem;
      border-radius: 0.1rem;
      box-shadow: $standardBoxShadow;
    }

    &:global(.bottom-middle) {
      &:before {
        bottom: 100%;
        right: 50%;
        transform: rotate(45deg) translateX(75%);
      }
    }
    &:global(.top-middle) {
      &:before {
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
    &:global(.right-middle) {
      &:before {
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
    &:global(.left-middle) {
      &:before {
        top: 50%;
        right: 0;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }
  }
}

.tooltipChildrenWrapper {
  cursor: pointer;
}
