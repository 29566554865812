@import 'common/variables';
@import 'common/mixins';

.mobilePopupContent {
  padding: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 11;
  border-top-left-radius: $standardBorderRadius;
  border-top-right-radius: $standardBorderRadius;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  transform: translateY(100%);
  @include transition(all);

  &.mobilePopupContentActive {
    opacity: 1;
    transform: translateY(0%);
  }
}

.dragHandle {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  width: 44px;
  height: 4px;
  background-color: #ffffff;
  margin: 0 auto;
  opacity: 0;
  border-radius: $standardBorderRadius;
  transform: translateY(100%);
  @include transition(all);

  &.dragHandleActive {
    opacity: 1;
  }
}

.mobilePopupWrapper {
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column;
}

.modalPopupTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  // flex-direction: column;
}

.mobilePopupTitle {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.mobilePopupSubtitle {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

.mobilePopupCloseBtn {
  color: $base-primary-600;
  font-size: 0.875rem;
}

.mobilePopupButtonGroup {
  display: flex;
  margin-top: 30px;
  width: 100%;
  gap: 10px;
  & button {
    width: 100%;
    padding: 10px auto;
    justify-content: center;
    border-radius: 64px;
  }

  & .download {
    color: $base-0;
    background-color: $base-primary-600;
  }

  & .close {
    color: $base-primary-600;
    background-color: $base-primary-0;
  }
}

.mobilePopupSelectItem {
  justify-content: space-between;
  background-color: #ffffff;
}
