@import 'variables';

html,
body:root {
  --base-primary-600: #{$base-primary-600};
  --base-primary-300: #{$base-primary-300};
  --base-primary-200: #{$base-primary-200};
  --base-primary-100: #{$base-primary-100};
  --base-primary-0: #{$base-primary-0};

  --base-900: #{$base-900};
  --base-700: #{$base-700};
  --base-500: #{$base-500};
  --base-200: #{$base-200};
  --base-100: #{$base-100};
  --base-50: #{$base-50};
  --base-bg: #{$base-bg};

  --deepBlue: #{$deepBlue};
  --skyBlue: #{$skyBlue};
  --deepOrange: #{$deepOrange};
  --standardBorderRadius: #{$standardBorderRadius};
  --standardBoxShadow: #{$standardBoxShadow};
  --largestContainer: #{$largestContainer};
  --largeContainer: #{$largeContainer};
}
