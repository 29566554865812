@use 'common/mixins';
@import 'common/variables';

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  background-color: #ffffff;
  border: 0.0675rem solid rgba($base-primary-600, 0.2);
  transform: translate(-50%, -40%);
  padding: 2rem;
  border-radius: $standardBorderRadius / 0.75;
  width: 95vw;
  opacity: 0;
  @include mixins.transition2(opacity, transform);

  &.modalActive {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.modalCloseBtn {
  margin-left: auto;
  color: rgba($dark, 0.4);
  @include mixins.transition(color);

  &:hover {
    color: $dark;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.modalSubtitle {
  font-size: 1rem;
  color: rgba($dark, 0.4);
}

.modalTitle {
  font-weight: 500;
  color: $base-900;
  font-size: 1.9rem;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;

  .dot {
    border-radius: 50%;
    background-color: $base-primary-600;
    animation: loading 1s ease-in-out infinite;
    opacity: 0.5;
    position: absolute;

    &:first-child {
      top: 0;
      left: 0;
      animation-delay: 0s;
      transform: translateX(-25%);
    }

    &:nth-child(2) {
      animation-delay: 0.66s;
      bottom: 0;
    }

    &:last-child {
      top: 0;
      right: 0;
      animation-delay: 0.33s;
      transform: translateX(25%);
    }
  }
}

@keyframes loading {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}
