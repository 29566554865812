@import '../common/mixins';
@import '../common/variables';

.ttsMessagesWrapper {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  @include transition2(width, opacity);

  &.ttsMessagesWrapperActive {
    width: unset;
    flex-grow: 1;
    opacity: 1;
    overflow: unset;
    height: auto;
    max-height: 660px;

    @media (max-width: 970px) {
      width: 100%;
    }
  }
}

.ttsMessageItemsWrapper {
  margin-top: 0.5rem;

  @media (min-width: 971px) {
    height: calc(100vh - 56px - 80px - 40px - 66px);
  }
}

.ttsMessageItem {
  background: #fff;
  border-radius: $standardBorderRadius;
  box-shadow: $standardBoxShadow;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.ttsMessageItemHeader {
  display: flex;
  padding: 25px;
}

.ttsVoiceInfo {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  flex-grow: 1;
  flex-shrink: 1;

  & button {
    color: rgba($dark, 0.4);
    @include transition(color);

    &:hover {
      color: $dark;
    }
  }
}

.ttsVoiceAvatarWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & span {
    font-size: 1rem;
    @include truncate;
  }
}

.ttsAvatar {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  overflow: hidden;
  background: $base-primary-100;
  margin-right: 12px;
  flex-shrink: 0;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ttsMessageText {
  line-height: 1.5rem;
  max-width: 90%;

  &.ttsMessageTextHidden {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: calc(2 * 1.4em);

    @media (max-width: 450px) {
      max-width: 60vw;
    }
  }

  &::placeholder {
    color: $base-600;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 90%;
    background: #0015350d;
    border-radius: $standardBorderRadius;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;

    background: #00153566;
    border-radius: $standardBorderRadius;
  }

  @media (max-width: 970px) {
    background: $base-50;
    padding: 1rem;
    border-radius: $standardBorderRadius;
    max-height: 15.625rem;
    max-width: 82%;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateY(-8px);
  }

  @media (max-width: 450px) {
    max-width: 75%;
  }
}

.ttsMessageSettingsPanel {
  display: flex;
  justify-content: space-between;
  padding: 0px 25px 25px;

  @media (max-width: 480px) {
    flex-direction: column-reverse;
    justify-content: center;

    & p {
      padding-left: 40px;
    }
  }
}

.ttsMessageSettings {
  display: flex;
  margin-left: 42px;
  gap: 8px;
  height: 16px;
}

.ttsMessageSettingsItem {
  color: rgba($dark, 0.4);
  font-size: 0.75rem;

  &:nth-child(2) {
    border-right: 1px solid rgba(0, 21, 53, 0.1);
    border-left: 1px solid rgba(0, 21, 53, 0.1);
    padding: 0px 8px;
  }
}

.ttsMessagePlayerWrapper {
  position: relative;
  // gap: 8px;
  grid-template-columns: 6 / 11;

  @media (max-width: 768px) {
    order: 2;
    grid-column: 1 / 4;
  }
}

.ttsMessageBtn {
  display: flex;
  margin: auto 0;
  display: flex;
  align-items: center;
  gap: 1rem;

  & a,
  button {
    display: flex;
    color: rgba($dark, 0.4);
    @include transition(color);

    &:hover {
      color: $dark;
    }
  }

  @media (max-width: 768px) {
    transform: translateX(7px);
  }
}

.ttsMessageСrossBtn {
  position: absolute;
  top: 5px;
  right: -3px;
  margin: auto 0;
  display: flex;
  align-items: center;
  gap: 1rem;

  & a,
  button {
    color: rgba($dark, 0.4);
    @include transition(color);

    &:hover {
      color: $dark;
    }
  }
}

.ttsGenerationTime {
  color: rgba($dark, 0.4);
  font-size: 0.75rem;

  @media (max-width: 970px) {
    text-align: left;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
}

.ttsVoiceMessageItem {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 82px 2fr 30px;
  padding: 25px 25px;
  border-top: 3px solid $base-100;

  @media (max-width: 768px) {
    grid-template-columns: 55% 40% 5%;
    grid-template-rows: 1fr 1fr;
    row-gap: 10px;
  }
}

.ttsMessagePreview {
  display: flex;
  padding-right: 35px;
  margin: auto 0;
  gap: -10px;
  @media (max-width: 450px) {
    display: none;
  }
}

.ttsMessagePreviewItem {
  border: 3px solid white;
  margin-right: -10px;
}
