@import 'common/variables';
@import 'common/mixins';

.tooltipWrapper {
  position: relative;
  display: flex;
}

.tooltipContent {
  padding: 1rem;
  text-align: left;
  background-color: #fff;
  max-width: 16.5rem;
  width: max-content;
  line-height: 1.5rem;
  color: $base-primary-600;
  font-size: 1rem;
  border-radius: 0.75rem;
  border: 0.0625rem solid $base-primary-0;
  opacity: 0;
  pointer-events: none;
  transition: 0.35s opacity ease;
  position: absolute;
  top: 0;
  z-index: 1000;
  box-shadow: $standardBoxShadow;

  &.tooltipActive {
    opacity: 1;
    pointer-events: all;
  }
}

.infoTooltipIcon {
  color: rgba($base-primary-600, 0.5);
  display: flex;
  @include transition(color);

  &:hover {
    color: $base-primary-600;
  }
}

.portalTooltipWrapper {
  position: absolute;
  transition: 0.35s all ease;
  z-index: 100;
}

.portalTooltipContent {
  background: $base-400;
  padding: 0.75rem;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 12px;
  max-width: 15rem;
  box-shadow: 0px 4px 8px rgba(0, 21, 53, 0.04);

  &.triangledContent {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      background: $base-400;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.1rem;
    }

    &:global(.bottom-middle) {
      &:before {
        bottom: 100%;
        right: 50%;
        transform: rotate(45deg) translateX(75%);
      }
    }
    &:global(.bottom-right) {
      &:before {
        width: 1rem;
        height: 1rem;
        bottom: 100%;
        right: 75%;
        transform: rotate(45deg) translateX(75%);
        box-shadow: -4px -4px 8px rgba(0, 21, 53, 0.04), -2px -2px 2px rgba(0, 21, 53, 0.04),
          0px 0px 0px rgba(0, 21, 53, 0.04);
      }
    }
    &:global(.top-middle) {
      &:before {
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
    &:global(.right-middle) {
      &:before {
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
    &:global(.left-middle) {
      &:before {
        top: 50%;
        right: 0;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }
  }
}

.tooltipChildrenWrapper {
  cursor: pointer;
}
