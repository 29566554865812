@import 'common/mixins';
@import 'common/variables';

.player {
  display: flex;
  align-items: center;
}

.playerTime {
  color: rgba($dark, 0.6);
  font-size: 0.875rem;
  width: 2.5rem;

  &.right {
    text-align: right;
  }
}

.waveForm {
  flex-grow: 1;
  margin: 0 0.5rem;
  overflow-x: hidden;
  // width: 300px;
}

.playPauseBtn {
  display: flex;
  color: $base-primary-600;
  @include transition(color);
  margin-right: 0.5rem;

  &:hover {
    opacity: 0.7;
  }
}

.volumeBtn {
  display: flex;
  margin-left: 0.5rem;
  color: rgba($dark, 0.4);
  @include transition(color);
  &:hover {
    color: $dark;
  }
}

.volumeRange {
  display: flex;
  align-items: center;
  width: 180px;
}

.playerRange {
  -webkit-appearance: none;
  width: 100%;
  height: 0.5rem;
  background: rgba($base-primary-600, 0.1);
  border-radius: $standardBorderRadius;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: $base-primary-600;
    border: none;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: $base-primary-600;
    border: none;
    cursor: pointer;
  }

  &::-ms-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: $base-primary-600;
    border: none;
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
  }

  &::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    height: 100%;
    background: #0015350d;
  }

  &::-moz-range-progress {
    background-color: $base-primary-600;
    height: 100%;
    border-radius: $standardBorderRadius * 2 / 3;
  }

  &::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: #0015350d;
  }

  &:focus {
    outline: none;
  }
}
