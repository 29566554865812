@import 'common/variables';

.asrFilterWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 10;
}

.asrFilterInput {
  padding: 12px;
  flex-grow: 1;
  border-radius: $standardBorderRadius;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: $standardBoxShadow;
  color: $base-900;
  background-color: $base-50;

  &::placeholder {
    color: $base-500;
  }

  @media (max-width: 332px) {
    width: 70%;
  }
}

.filterTextButtonMobileWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filterTextButtonMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterTextButtons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 230px;
  padding: 5px;

  button {
    justify-content: space-between;
  }
}

.filterTextTitle {
  line-height: 16px;
  font-size: 12px;
  color: $base-700;
}

.asrListFilterBtns {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 0 auto;

  & .active {
    color: $base-primary-600;
    background-color: $base-primary-0;
  }

  @media (max-width: 970px) {
    flex-direction: unset;
  }
}

.filterTextButtonActive {
  color: $base-primary-600;
  background-color: $base-primary-0;
  border-color: $base-primary-600;
}

.asrListFiltersWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 180px;
  @media (max-width: 970px) {
    flex-direction: column-reverse;
  }
}

.asrListFilterSelector {
  width: 12rem;

  @media (max-width: 970px) {
    width: 100%;
  }
}

.asrListFilterItem {
  & > span:first-child {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
}

.asrFilterCustomButton {
  flex-shrink: 0;
}

.filterBtn {
  padding: 0;
  &:hover {
    color: $base-primary-600;
    background-color: $base-primary-0;
  }
}

.tooltipFilterClassName {
  padding: 0.75rem;
  display: flex;

  & > div {
    display: flex;
  }
}
