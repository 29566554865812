.ttsContent {
  display: flex;
  gap: 1rem;
  margin-top: 1.1rem;
  align-items: flex-start;

  @media (max-width: 970px) {
    flex-direction: column;
  }
}
