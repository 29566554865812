@import 'styles/common/font';
@import 'styles/common/theme';
@import 'styles/common/variables';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  color: $base-900;
  font-size: 16px;
  line-height: 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

h1 {
  font-size: 2.1rem;
  line-height: 2.5rem;
  font-weight: 600;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
}
