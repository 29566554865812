@import 'common/variables';

.errorPageWrapper {
  width: 100vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  line-height: 3.5rem;

  &:last-child {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
  }
}

.errorPageLink {
  color: $base-primary-600;
  cursor: pointer;
}
