@use 'common/mixins';
@import 'common/variables';

.optionButtonsWrapper {
  border-radius: $standardBorderRadius;
  display: inline-flex;
  background-color: $base-bg;

  &:global(.inactive) {
    pointer-events: none;
    .optionButton.optionsButtonActive {
      background: rgba($dark, 0.1) !important;
    }
  }
}

.optionButton {
  text-align: center;
  padding: 0.75rem 0.5rem;
  width: 6.55rem;
  color: $base-500;
  font-size: 1rem;
  border-radius: $standardBorderRadius / 1.5;
  @include mixins.transition2(color, background);
  @include mixins.truncate;

  &:hover {
    color: rgba($base-primary-600, 0.8);
    background-color: rgba($base-primary-600, 0.1);
  }

  &.optionsButtonActive {
    font-weight: 500;
    @include mixins.transition2(color, background);
    color: $base-100;
    background-color: $base-primary-600;
  }
}
